import React, { memo, useEffect, useState } from 'react';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { signIn, useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { Icon, IconButton, Link } from 'components';
import { Form as FormComponent } from 'components/form';
import Logo from 'components/Logo';
import { ButtonV3, InputV2 } from 'components/ComponentV2';
import Row from 'components/layout/Row';
import Avatar from 'components/Avatar';
import { useCandidate } from 'hooks/useCandidate';

import { useResponsive } from 'hooks/useResponsive';
import { useAuth } from 'lib/providers/AuthProvider';
import { REGEX } from 'lib/utils/regex';
import { parseErrorResponse, parseResponse } from 'lib/utils/parser';
import { TUserApiResponse } from 'lib/models/User.model';
import { StudentSocietyAttributes } from 'lib/models/student-society';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useCommonList } from 'lib/contexts/common-list-context';
import { PRIVACY_POLICY, TERMS_OF_USE } from 'utils/urls';
import { isCandidateUserable } from 'utils/user';
import { VerifyOTP } from '../VerifyOTP/VerifyOTP.component';

import styles from './SignIn.module.scss';

export enum USER_TYPE {
  NEW = 'NEW',
  OLD = 'OLD',
}

export interface SignInProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** callback fn to be called when sign in is successful */
  onComplete: (isOnboardingCompleted?: boolean) => void;
  isLoginPopup: boolean;
  signInFromSociety?: boolean;
  societyInfo?: StudentSocietyAttributes;
  utm_source?: string;
}

export const SignIn: React.FC<SignInProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    onComplete,
    isLoginPopup = false,
    signInFromSociety = false,
    societyInfo,
    utm_source,
  }: SignInProps) => {
    const { data: session, status } = useSession();
    const router = useRouter();
    const fromProductHunt = utm_source === 'producthunt';
    const screens = useResponsive();

    const { setIsAuthLoading, updateUserApiResponse } = useAuth();
    const [t] = useTranslation('common');
    const {
      configData: { jobs_count, company_count },
      isConfigDataLoading,
    } = useCommonList();

    const [askOTP, setAskOtp] = useState(false);
    const [isDomainChecked, setDomainChecked] = useState(false);
    const [showError, setShowError] = useState('');
    const [isLoading, setLoading] = useState(false);

    const {
      onGoogleLoginAuthentication,
      onAppleLoginAuthentication,
      loginCandidate,
      isNewCandidate,
    } = useCandidate();

    const {
      register,
      watch,
      setValue,
      handleSubmit,
      formState: { errors, isSubmitting },
    } = useForm({ mode: 'all' });

    const emailValue = watch('email');

    const onGoogleLogin = async () => {
      const callbackUrl = router.asPath;
      await signIn('google', {
        callbackUrl,
      });
    };

    const onAppleLogin = () => {
      const callbackUrl = router.asPath;
      signIn('apple', {
        callbackUrl,
      });
    };

    const isLoggedInViaGoogle = session && session.oauth_provider === 'google';
    const isLoggedInViaApple = session && session.oauth_provider === 'apple';

    const onSocialLoginResponse = (response: TUserApiResponse) => {
      const parsedResponse = parseResponse(response);
      if (!isLoginPopup && isCandidateUserable(parsedResponse)) {
        updateUserApiResponse(response);
        onComplete(parsedResponse?.onboarding_completed);
      } else {
        setShowError(t('candidate-signin.error-message'));
      }
      setLoading(false);
    };

    useEffect(() => {
      if (
        status === 'authenticated' &&
        (isLoggedInViaGoogle || isLoggedInViaApple)
      ) {
        setLoading(true);
        const { user } = session;
        const { email = '' } = user || {};
        setValue('email', email);
        try {
          (async () => {
            if (isLoggedInViaGoogle) {
              const response = await onGoogleLoginAuthentication();
              if (response) {
                onSocialLoginResponse(response);
              }
            }
            if (isLoggedInViaApple) {
              const response = await onAppleLoginAuthentication();
              if (response) {
                onSocialLoginResponse(response);
              }
            }
          })();
        } catch (error) {
          const message = parseErrorResponse(error);
          setShowError(message);
          setLoading(false);
        }
      }
    }, [status, isLoggedInViaGoogle, isLoggedInViaApple]);

    useEffect(() => {
      if (emailValue) {
        setDomainChecked(false);
      }
    }, [emailValue]);

    useEffect(() => {
      router.prefetch(PAGE_ROUTES.CANDIDATE_ONBOARDING);
    }, []);

    const onContinue = async () => {
      try {
        setIsAuthLoading(true);
        if (isLoggedInViaGoogle) {
          const response = await onGoogleLoginAuthentication();
          if (response) {
            onSocialLoginResponse(response);
          }
        } else if (isLoggedInViaApple) {
          const response = await onAppleLoginAuthentication();
          if (response) {
            onSocialLoginResponse(response);
          }
        } else {
          const response = await loginCandidate({
            email: emailValue,
            check_email: isDomainChecked ? false : true,
          });
          if (response) {
            if (isNewCandidate(response)) {
              updateUserApiResponse(response as TUserApiResponse);
              if (onComplete) {
                onComplete(false);
              }
            } else {
              setAskOtp(true);
            }
          }
        }
      } catch (error) {
        const message = parseErrorResponse(error);
        setShowError(message);
        setDomainChecked(true);
      } finally {
        setIsAuthLoading(false);
      }
    };

    const contentClassNames = cn(
      [styles.SignIn],
      {
        [styles.otpContainer]: askOTP,
      },
      className
    );

    return (
      <section className={contentClassNames} style={style} data-testid="SignIn">
        {!signInFromSociety && fromProductHunt && (
          <div className={styles['producthunt-banner']}>
            <div className={styles.branding}>
              <Icon
                iconName="icon_producthunt"
                size={screens.sm ? 'small' : 'mediumplus'}
              />
              <p className={styles.title}>
                {t('candidate-signin.producthunt-banner.title')}
              </p>
            </div>
            <p className={styles.tag}>
              {t('candidate-signin.producthunt-banner.tag')}
            </p>
          </div>
        )}
        {askOTP ? (
          <>
            <IconButton
              iconName="chevron-left"
              size="xlarge"
              onClick={() => setAskOtp(false)}
              className={styles.chevron}
            />
            <VerifyOTP
              email={emailValue}
              onComplete={onComplete}
              isLoginPopup={isLoginPopup}
              className={cn({
                [styles.verifyOTP]: !isLoginPopup,
                [styles.verifyOTPPopup]: isLoginPopup,
              })}
            />
          </>
        ) : (
          <>
            <div
              className={cn(
                { [styles.content]: !isLoginPopup },
                { [styles.contentPopup]: isLoginPopup }
              )}
            >
              <Logo
                name="huzzle-new"
                size="small"
                color="primary"
                className={cn(styles.logo, styles.hideDesktop, {
                  [styles.hideMobile]: isLoginPopup,
                })}
              />
              {!signInFromSociety ? (
                fromProductHunt ? (
                  <div className={styles['producthunt-info']}>
                    <p className={styles['producthunt-title']}>
                      {t('candidate-signin.producthunt-title')}
                    </p>
                    <p className={styles['producthunt-offer']}>
                      {t('candidate-signin.producthunt-offer')}
                    </p>
                  </div>
                ) : (
                  <>
                    <p
                      className={cn(styles.title, {
                        [styles.titleMobile]: !isLoginPopup,
                      })}
                    >
                      {t('candidate-signin.title')}
                    </p>
                    <p
                      className={cn(styles.desc, {
                        [styles.hideMobile]: !isLoginPopup,
                      })}
                    >
                      {t('candidate-signin.desc')}
                    </p>
                  </>
                )
              ) : (
                <div className={styles['join-society']}>
                  <div>
                    <Row justify="center" className={styles['society-logo']}>
                      <Avatar
                        icon={societyInfo?.icon_url}
                        className={styles['society-logo']}
                        name={societyInfo?.name as string}
                        size="xxlarge"
                      />
                    </Row>
                    <p className={styles.title}>Join {societyInfo?.name}</p>
                  </div>
                  <Row className={styles.societyText}>
                    <div className={styles.societyTextContainer}>
                      <Icon
                        iconName="icon_check"
                        size="xsmallplus"
                        className={styles.icon}
                      />
                      <p className={styles.text}>
                        {t('society-signin-desc-1')}
                      </p>
                    </div>
                    <div className={styles.societyTextContainer}>
                      <Icon
                        iconName="icon_check"
                        size="xsmallplus"
                        className={styles.icon}
                      />

                      <p className={styles.text}>
                        {t('society-signin-desc-2')}
                      </p>
                    </div>
                    <div className={styles.societyTextContainer}>
                      <Icon
                        iconName="icon_check"
                        size="xsmallplus"
                        className={styles.icon}
                      />

                      <p className={styles.text}>
                        {t('society-signin-desc-3')}
                      </p>
                    </div>
                  </Row>
                </div>
              )}
              <div
                className={cn(
                  { [styles.gap]: !isLoginPopup },
                  styles.actionContainer,
                  { [styles.actionContainerSociety]: signInFromSociety }
                )}
              >
                <ButtonV3
                  size="large"
                  color="secondary"
                  startIcon="icon_google"
                  startIconClassName={styles.startIcon}
                  isFullWidth
                  className={styles.buttonGoogle}
                  onClick={onGoogleLogin}
                  disabled={isLoading}
                >
                  {t('candidate-signin.google-button')}
                </ButtonV3>
                <ButtonV3
                  size="large"
                  color="secondary"
                  startIcon="icon_apple"
                  startIconClassName={styles.startIcon}
                  isFullWidth
                  onClick={onAppleLogin}
                  className={styles.buttonApple}
                  disabled={isLoading}
                >
                  {t('candidate-signin.apple-button')}
                </ButtonV3>
                <Row
                  align="center"
                  justify="center"
                  columnGap={12}
                  isFullWidthRow
                  className={styles.separatorRow}
                >
                  <div className={styles.separator}></div>
                  <p className={styles.separatorText}>
                    {t('candidate-signin.or')}
                  </p>
                  <div className={styles.separator}></div>
                </Row>
                <FormComponent
                  autoComplete="off"
                  onSubmit={handleSubmit(onContinue)}
                  className={styles.sectionForm}
                >
                  <InputV2
                    type="email"
                    inputClassName={styles.signInput}
                    placeholder={t('candidate-signin.email-placeholder')}
                    {...register('email', {
                      required: t('required'),
                      pattern: {
                        value: REGEX.EMAIL,
                        message: t('valid-error-message'),
                      },
                    })}
                  />
                  {showError && (
                    <div className={styles.error}>
                      <p>
                        😅{'  '}{' '}
                        <span
                          dangerouslySetInnerHTML={{ __html: showError }}
                        ></span>
                      </p>
                      <IconButton
                        iconName="close"
                        size="xsmallplus"
                        onClick={() => {
                          setShowError('');
                          setDomainChecked(true);
                        }}
                        className={styles.cancelError}
                      />
                    </div>
                  )}
                  <ButtonV3
                    size="large"
                    isFullWidth
                    disabled={!!errors.email || !emailValue}
                    className={styles.buttonContinue}
                    type="submit"
                    isLoading={isSubmitting || isLoading}
                  >
                    {t('candidate-signin.continue-with-email')}
                  </ButtonV3>
                </FormComponent>
                <p className={styles.termsRow}>{t('candidate-signin.terms')}</p>
                <p className={styles.termsText}>
                  <span>
                    <Link
                      isUnderlined
                      href={TERMS_OF_USE}
                      className={styles.termsText}
                    >
                      {t('candidate-signin.terms-of-service')}
                    </Link>
                    {' and '}
                    <Link
                      isUnderlined
                      href={PRIVACY_POLICY}
                      className={styles.termsText}
                    >
                      {t('candidate-signin.privacy-policy')}
                    </Link>
                  </span>
                </p>
              </div>
            </div>
            {!signInFromSociety && (
              <section className={styles.logos}>
                {!isConfigDataLoading ? (
                  <p className={styles.logosText}>
                    {t('candidate-signin.logos-row', {
                      jobs_count,
                      company_count,
                    })}
                  </p>
                ) : null}
                <Row align="center" justify="center" columnGap={25}>
                  <Icon iconName="logo_deloitte" className={styles.logosIcon} />
                  <Icon iconName="logo_google" className={styles.logosIcon} />
                  <Icon iconName="logo_hsbc" className={styles.logosIcon} />
                  <Icon
                    iconName="logo_airbus"
                    className={cn(styles.logosIcon, styles.hideMobile)}
                  />
                </Row>
              </section>
            )}
          </>
        )}
      </section>
    );
  }
);

SignIn.displayName = 'SignIn';
