import React, { memo } from 'react';
import cn from 'classnames';

import { Icon, Image } from 'components';

import styles from './Logo.module.scss';

export type LogoIconName =
  | 'huzzle'
  | 'huzzle.recruiter'
  | 'huzzle.society'
  | 'huzzle-new'
  | 'icon_huzzlerecruiter';
export type LogoIconSize = 'xsmall' | 'small' | 'medium' | 'large';

export interface LogoProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** name of the logo */
  name: LogoIconName;
  /** color */
  color: 'white' | 'black' | 'primary';
  /** size */
  size: LogoIconSize;
  /** dot color */
  dotColor?: 'primary' | 'black';
  onClick?: () => void;
}

export const Logo: React.FC<LogoProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    name,
    color,
    size = 'small',
    dotColor = 'primary',
    onClick,
  }: LogoProps) => {
    const iconClassNames = cn(
      styles.icon,
      {
        [styles.colorWhite]: color === 'white',
        [styles.colorBlack]: color === 'black',
        [styles.colorPrimary]: color === 'primary',
        [styles.sizeSmall]: size === 'small',
        [styles.sizeXSmall]: size === 'xsmall',
        [styles.sizeLarge]: size === 'large',
        [styles.sizeMedium]: size === 'medium',
        [styles.dotPrimary]: dotColor === 'primary',
        [styles.dotBlack]: dotColor === 'black',
      },
      className
    );

    return name === 'huzzle-new' ? (
      <Image
        style={style}
        className={iconClassNames}
        src="/images/huzzle-logo-2.png"
        onClick={onClick}
        alt="Logo of Huzzle"
      />
    ) : (
      <Icon
        iconName={name}
        style={style}
        className={iconClassNames}
        size="auto"
      />
    );
  }
);

Logo.displayName = 'Logo';
